import React, { useState, useEffect } from 'react';
import BackgroundSuperbet from '../../assets/fundo.png';
import BackgroundWill from '../../assets/will/fundo.png';
import styles from './styles';

const Home = () => {
    const [progress, setProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [backgroundImage, setBackgroundImage] = useState(BackgroundSuperbet);
    const [progressBarStyle, setProgressBarStyle] = useState(styles.progressBar);

    function generate() {
        return 'event_' + Date.now();
    }

    useEffect(() => {
        const getPathSegments = () => {
            const path = window.location.pathname;
            const segments = path.split('/').filter(Boolean);
            return segments;
        };

        const segments = getPathSegments();

        if (segments.length < 2) {
            setLoadingMessage('❌ URL inválido!');
            return;
        }

        const [platform, source] = segments;

        if (platform === 'will') {
            setBackgroundImage(BackgroundWill);
            setProgressBarStyle(styles.progressBar2);
        } else if (platform === 'superbet') {
            setBackgroundImage(BackgroundSuperbet);
            setProgressBarStyle(styles.progressBar);
        }

        const redirectUrl =
            platform === 'superbet'
                ? `https://wlsuperbet.adsrv.eacdn.com/C.ashx?btag=a_5800b_378c_&affid=678&siteid=5800&adid=378&c=${source}`
                : platform === 'will'
                ? `https://igoal.go2cloud.org/aff_c?offer_id=6041&aff_id=3166&source=${source}`
                : null;

        if (!redirectUrl) {
            setLoadingMessage('❌ Plataforma desconhecida!');
            return;
        }

        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    setLoadingMessage('✅ Concluído!');

                    if (typeof window.fbq === 'function') {
                        const eventId = generate();
                        window.fbq('track', platform, { eventID: eventId });
                    }

                    console.log("Redirecionando para: ", redirectUrl)

                    setTimeout(() => {
                        window.location.replace(redirectUrl);
                    }, 100);

                    return 100;
                }
                const diff = 100 / 40;
                const newProgress = Math.min(oldProgress + diff, 100);
                updateLoadingMessage(newProgress);
                return newProgress;
            });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const updateLoadingMessage = (progress) => {
        if (progress < 40.33) {
            setLoadingMessage('⏳ Carregando página...');
        } else if (progress < 72.66) {
            setLoadingMessage('🔓 Conexão segura...');
        } else if (progress < 85) {
            setLoadingMessage('✅ Concluído!');
        }
    };

    return (
        <div style={{ ...styles.backgroundContainer, backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div style={styles.progressContainer}>
                <div className="loading-message" style={styles.loadingMessage}>{loadingMessage}</div>
                <div style={styles.progressBarContainer}>
                    <div style={{ ...progressBarStyle, width: `${progress}%` }} />
                </div>
            </div>
        </div>
    );
};

export default Home;
